/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

body {
  overflow: auto;
}
.pusher > .segment > .ui.menu {
  margin: -1em -1em 1em -1em;
}
.ui.left.sidebar, .ui.right.sidebar, .ui.large.vertical.sidebar.menu {
    width: 260px;
}
.ui.visible.left.sidebar ~ .fixed,
.ui.visible.left.sidebar ~ .pusher,
.ui.default-visibility.left.sidebar ~ .fixed,
.ui.default-visibility.left.sidebar ~ .pusher {
  margin-left: 260px;
  -webkit-transform: none;
  transform: none;
}
.ui.vertical.sidebar.menu.default-visibility,
.ui.vertical.sidebar.menu.visible {
  display:block;
  visibility: visible;
  transform: none;
}
@media only screen and (max-width: 767px) {
  .ui.vertical.sidebar.menu.default-visibility {
    display:none;
  }
  .ui.visible.left.sidebar ~ .fixed,
  .ui.visible.left.sidebar ~ .pusher,
  .ui.default-visibility.left.sidebar ~ .fixed,
  .ui.default-visibility.left.sidebar ~ .pusher {
    margin-left: 0;
  }
  .visible.toggle-sidebar.item {
    position: fixed;
    left: 260px;
    z-index: 1000;
    background: #fff !important;
  }
}

.ui.vertical.menu .menu .menu .item {
  padding-left: 2rem;
}

#root > .pusher {
  padding-bottom: 5rem; /* let Sticky not twitch */
}

.clickable {
  cursor: pointer;
}

div > .ui.fullscreen.scrolling.modal,
div > .ui.fullscreen.modal,
.ui.fullscreen.modal {
  /* fix <= v2.4.1 */
  left: 2.5% !important;
}
.modals.dimmer[class*="top aligned"] .ui.scrolling.modal, /* do not change modal position when content become scrollable */
.modals.dimmer[class*="top aligned"] .modal {
  margin: 5vh auto;
}

a > i.icon {
  height: auto;
}

a.ui.button {
  text-decoration: underline;
}

/* TODO: move to respective files */

.ui.loading.segment + .ui.loading.segment {
  display: none;
}

.ui.loading.logout.button::after {
  border-top-color: red;
}

.main-frame .ui.breadcrumb,
.ui.breadcrumb {
  display: block;
  margin-bottom: 1rem;
}
.expand-hellip {
  border: none;
  background: none;
  padding: 0;
  line-height: inherit;
  color: #4183c4;
  cursor: pointer;
}

.resource-collection-nav-form > .button, .resource-collection-nav-form > .buttons {
  margin-top: 0.25rem;
}

.ui.compact.table.data-table > thead > tr > th,
.data-table > thead > tr > th {
  padding: 0.7em;
}
.data-table > thead > tr > th > .dropdown {
  padding: 0.7em;
  margin: -0.7em;
  display: block;
}
.data-table > thead > tr:hover > th > .dropdown {
  text-decoration: underline;
}

.ui.sortable.table thead th {
  white-space: normal;
}
.data-table-parent,
.resource-collection-as-table {
  overflow: auto;
}
.resource-collection.table tr {
  cursor: cell;
}

.ui.dropdown .one-line-menu.menu,
.one-line-menu.menu {
  margin-top: -0.2rem;
}
.ui.dropdown .one-line-menu.menu > .item,
.one-line-menu.menu > .item {
  display: inline-block;
  padding: 0.7rem 1rem !important;
}

.grid.with-bottom-aux-frame > .main-frame .resource-collection-as-table,
  .grid.with-bottom-aux-frame > .main-frame .resource-collection-as-items {
  max-height: 40vh;
  overflow: auto;
}

.ui.basic.button.clear-input {
  position: absolute;
  right: 0;
  margin-right: 0;
  box-shadow: none;
}
.ui.basic.button.clear-input:hover {
  background: transparent none !important
}

.ui.placeholder.segment .ui.header {
  color: rgba(0,0,0,.87);
}
.ui.placeholder > .ui.loading.button::before {
  background-color: transparent;
}

.to-many-relationships {
  margin-top: 2rem;
}
.to-many-relationships > .ui.menu {
  overflow-x: auto;
  overflow-y: hidden;
}
.to-many-relationships > .ui.menu .item {
  flex: auto;
}
.to-many-relationships > .ui.pointing.menu .item::after {
  top: calc(100% - .57142857em);
}

.ui.segment.single-view-buttons:empty {
  padding: 0;
}
.ui.sticky {
  max-height: 40vh;
  overflow: auto;
}
.resource-sticky {
  width: 100%;
}
.ui.segment.resource-sticky-segment {
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  clear: both;
}
.ui.segment.resource-sticky-segment:empty {
  padding: 0;
}
.ui.form > .ui.list {
  margin-top: 0;
}
.ui.grid.relaxed.list {
  margin-left: -1rem;
  margin-right: -1rem;
}
.ui.divided.grid.relaxed.list:not([class*="vertically divided"]) > .column:not(.row), .ui.divided.grid.relaxed.list:not([class*="vertically divided"]) > .row > .column {
  box-shadow: none;
}
.ui.relaxed.list:not(.horizontal) > .item {
  padding-top: 0.42857143em;
}
.ui.divided.list .list > .item:first-child, .ui.divided.list > .item:first-child {
  border-top: 1px solid rgba(34,36,38,.15);
}

.item-field-list {
  margin: 0;
  padding: 0;
}
.ui.items > .item .meta .item-field {
  margin-right: 0;
}
.item-field {
  margin-right: 0;
  display: inline;
}
.item-field + .item-field::before {
  content: '; ';
}

.first-aux-frame > .ui.buttons > .button {
  margin-top: -0.75em;
}

.item > .content > .ui.button.item-menu {
  margin-top: -0.5em;
}
.ui.button.item-menu {
  margin-right: 0;
}
.ui.popup.menu-popup {
  padding: 0;
  border: none;
}

.quick-menu {
  position: relative;
  display: inline-block;
}
.ui.button.quick-menu-item {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  box-shadow: 0px 0 0 1px rgba(34,36,38,.15) inset;
  margin-left: -1px;
  z-index: 100;
}
.nested-resource:hover > .nested-resource-control > .quick-menu > .ui.button.quick-menu-item,
tr:hover > td > .quick-menu > .ui.button.quick-menu-item {
  display: block;
}
tr:hover > td > .quick-menu > .item-menu {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
tr:hover > td > .quick-menu > .quick-menu-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ui.button.quick-menu-item.delete-btn {
  background-color: red;
  color: white;
}

.header-buttons > .ui.button {
  margin-top: -0.5em;
}

.single-resource .ui.small.header {
  margin-top: 1.5rem;
}
.header-buttons2 {
  top: 1.5rem;
  position:relative;
}
.header-buttons2 > .ui.button {
  margin-top: -0.5em;
}
.mini.header-buttons2, .compact.header-buttons2 {
  margin-top: 3px;
}
.header-buttons2 > .compact.button {
  margin-top: -3px;
}

.empty.collection.segment {
  min-height: initial;
}
.empty.collection.segment > .header {
  opacity: 0.75;
  margin: 0;
}

.form-fields,
.single-resource.full-view > form,
.single-resource.full-view {
  width: 100%;
  /* .ui.grid { */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.single-view-buttons {
  width: 100%;
}
.form-field,
.full-view .resource-field {
  padding: 0.42857143em 0;

  position: relative;
  display: inline-block;
  width: 100%;
  table-layout: fixed;

  padding-right: 0.5em;
}
.full-view td .resource-field {
  width: 100%;
}
.flex-break {
  flex-basis: 100%;
  height: 0;
}
.full-view.reading .flex-break {
  border-top-width: 1px;
  margin-bottom: -1px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .form-fields > .form-field,
  .full-view .resource-field {
    width: 100%;
  }
}
/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .form-fields > .form-field,
  .full-view .resource-field {
    width: 100%;
  }
}
/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .form-fields > .form-field,
  .folded.folder,
  .full-view .resource-field {
    width: 50%;
  }
  .grid.with-right-aux-frame .form-fields > .form-field,
  .grid.with-right-aux-frame .folded.folder,
  .grid.with-right-aux-frame .full-view .resource-field {
    width: 100%;
  }
}
/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .form-fields > .form-field,
  .folded.folder,
  .full-view .resource-field {
    width: 33.3%;
  }
  .grid.with-right-aux-frame .form-fields > .form-field,
  .grid.with-right-aux-frame .folded.folder,
  .grid.with-right-aux-frame .full-view .resource-field {
    width: 100%;
  }
}
/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  .form-fields > .form-field,
  .folded.folder,
  .full-view .resource-field {
    width: 25%;
  }
  .grid.with-right-aux-frame .form-fields > .form-field,
  .grid.with-right-aux-frame .folded.folder,
  .grid.with-right-aux-frame .full-view .resource-field {
    width: 50%;
  }
}
.folder-content > .form-field:only-child,
.full-view .folder-content > .resource-field:only-child {
  flex-grow: 4;
}

.folder {
  padding-right: 0.3rem;
}
.ui.fluid.placeholder,
.folder-content > .ui.message,
.folder, .folder-title, .folder-content {
  width: 100%;
}
.ui.header.folder-title {
  background: #ececec;
  color: rgba(0,0,0,0.8);
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  /* TODO: transform */
}
.ui.header.folder-title:hover {
  background: #cacaca;
}
.folded.folder > .folder-title::before {
  content: "\f0d9"
}
.unfolded.folder > .folder-title::before {
  content: "\f0d7"
}
.folder-title::before {
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  float: right;
  margin-right: 0.5rem;
  opacity: 0.6;
}
.folder-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.full-view.reading .flex-break,
.nested-resource.reading .resource-field.reading,
.full-view.reading .resource-field.reading {
  /* .ui.divided.list > .item { */
  border-top: 1px solid #dedede;
}
.full-view.reading .collection-navigation .resource-field.reading,
.full-view .collection-navigation .resource-field,
.full-view.reading .resource-collection-as-table .resource-field.reading,
.full-view .resource-collection-as-table .resource-field {
  border-top: none;
  padding: 0;
}
.full-view.editing .resource-field.reading {
  margin-top: 0.5em;
}
.nested-resource.reading .resource-field.reading {
  margin-top: 0;
}
.form-field,
.full-view .resource-field {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0.3rem;
}
.form-field > .field-input,
.full-view .resource-field > .field-input {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.form-field > .field-input > div,
.full-view .resource-field > .field-input > div {
  width: 100%;
}
.form-field > .field-input > div.relationship-type-enum,
.full-view .resource-field > .field-input > div.relationship-type-enum {
  width: inherit;
}
.form-field > .ui.message,
.resource-field > .ui.message {
  width: 100%;
  margin-top: 0.25em;
  padding: 0.6em 1.5em;
}
.emptiness-label,
.form-field > .field-input > label,
.full-view .resource-field > .field-input > label {
  max-width: 50%;
  flex: 0 0 auto;
  align-items: center;

  /* TODO: from .ui.horizontal.label */
  margin: 0 .5em 0 0;
  padding: 0.4em 0.833em;
  min-width: 3em;
  text-align: center;

  /* TODO: from .ui.label */
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0,0,0,.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;

  margin-right: 0.5em;
}
.emptiness-label,
.form-field > .field-input > label,
.full-view .resource-field > .field-input > label,
.ui.button.relationship-type-enum {
  font-size: 0.8rem;
  display: flex;
  flex-basis: content;
}
.field-value,
.full-view .resource-field > .field-input > span,
.full-view .resource-field > .field-input > a {
  align-items: center;
  display: flex;
}

.form-field.editing > .field-input > .ui.button,
.full-view .resource-field.editing > .field-input > .ui.button {
  margin: 0;
  align-items: center;
}
.to-one-relationship.ui.selection.dropdown,
.form-field.editing > .field-input > label,
.full-view .resource-field.editing > .field-input > label,
.full-view .resource-field > .field-input > .dropdown {
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-field > .field-input input[type="text"], .form-field > .field-input input[type="text"]:focus,
.form-field > .field-input input:not([type]), .form-field > .field-input input:not([type]):focus,
.form-field > .field-input > label + .dropdown,
.form-field > .field-input > .dropdown + .dropdown,
.form-field > .field-input > .dropdown + button,
.form-field > .field-input label + label,
.full-view .resource-field > .field-input input[type="text"], .full-view .resource-field > .field-input input[type="text"]:focus,
.full-view .resource-field > .field-input input:not([type]), .full-view .resource-field > .field-input input:not([type]):focus,
.full-view .resource-field > .field-input > label + .dropdown,
.full-view .resource-field > .field-input > .dropdown + .dropdown,
.full-view .resource-field > .field-input > .dropdown + button,
.full-view .resource-field > .field-input label + label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-field > .field-input > .ui.checkbox,
.resource-field > .field-input > .ui.checkbox {
  top: 0.27em;
}
.form-field.editing > .field-input > .ui.checkbox,
.resource-field.editing > .field-input > .ui.checkbox {
  top: 0;
}

i.input-icon {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 1.25em;
  right: 0.5em;
  margin: 0;
  width: 2.67142857em;
  opacity: 0.5;
  pointer-events: none;
}
i.input-icon::before {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    margin-top: -0.5em;
}

.single-resource .ui.horizontal.label {
  max-width: 50%;
}

.resource-field.editing.required > .field-input > label {
  position: relative;
}
.resource-field.editing.required > .field-input > label::after {
  content: '*';
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 1rem;
}
.resource-field.editing.required.empty-value > .field-input > label::after {
  color: red;
}
.resource-field.required > .field-input > label.edited-field-state::after {
  content: '';
}
.form-field.editing > .field-input > label.edited-field-state,
.full-view .resource-field.editing > .field-input > label.edited-field-state {
  min-width: 1em;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.edited-field-state > i.icon {
  margin: 0;
}
.form-field > .field-input label.edited-field-state + label,
.full-view .resource-field > .field-input label.edited-field-state + label {
  padding-left: 0;
}
.data-table .resource-field > .field-input > label,
.full-view .resource-collection-as-table .resource-field > .field-input > label,
.resource-collection-as-table .field-input > label {
  display: none;
}
.data-table .resource-field[data-type="boolean"],
.resource-collection-as-table .resource-field[data-type="boolean"] {
  text-align: center;
}
.data-table .resource-field[data-type="number"],
.resource-collection-as-table .resource-field[data-type="number"] {
  text-align: right;
}
.full-view.reading .resource-collection-as-items .resource-field.reading {
  border: none;
  display: inline;
  padding: 0;
}
.full-view .resource-collection-as-items .resource-field > .field-input > label,
.resource-collection-as-items .field-input > label {
  color: #777;
  font-size: 1rem;
  font-weight: 100;
  background: none;
  display: inline;
  margin: 0;
  padding: 0;
}
.full-view .resource-collection-as-items .resource-field.reading > .field-input > span,
.full-view .resource-collection-as-items .resource-field.reading > .field-input > a,
.full-view .resource-collection-as-items .resource-field > .field-input {
  display: inline;
}
.resource-collection-as-items .field-input > label::after {
  content: ': ';
}
.resource-collection-as-items .resource-field {
  display: inline-block;
}
.resource-collection-as-items > .item > .content > .header:hover {
  cursor: cell;
}

.hover-buttons {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 3px;
  padding-left: 3px;
  z-index: 1100;
}
.hover-buttons > .ui.icon.button,
.hover-buttons > .ui.button,
.hover-buttons > .button {
  box-shadow: 0 0 3px #555;
  position: relative;
  z-index: 1150;
  vertical-align: top;
}
.ui.dimmer.modals {
  z-index: 1200;
}
.ui.modal.active,
.ui.modal {
  z-index: 1250;
}
.array-item-field > .hover-buttons {
  display: none;
}
.array-item-field:focus-within > .hover-buttons,
.array-item-field:hover > .hover-buttons {
  display: block;
}
.array-item-field > .hover-buttons:focus-within,
.array-item-field > .hover-buttons:hover {
  right: 0;
  bottom: 0;
}
.hover-buttons > .ui.dimmer {
  outline: 2px dashed #888;
  outline-offset: -2px;
  background-color: rgba(255,255,255,.75);
}
.hover-buttons:focus-within > .ui.dimmer,
.hover-buttons:hover > .ui.dimmer {
  opacity: 1;
  display: block;
  visibility: visible !important;
}
.hover-buttons > .ui.icon.button,
.hover-buttons > .ui.button {
  padding: 0;
}
.ui.icon.button.remove-button,
.ui.button.remove-button,
.remove-button {
  background-color: rgba(255,0,0,.95);
  border-color: rgba(255,0,0,.95);
  color: white;
}
.remove-button > i.icon {
  font-size: 0.9rem;
}
.remove-button:focus ~ .ui.dimmer,
.remove-button:hover ~ .ui.dimmer {
  background-color: rgba(255,0,0,.35);
}

.button.attention {
  position: relative;
}
.button.attention::after {
  content: '';
  position: absolute;
  width: 0.7em;
  height: 0.7em;
  top: -0.35em;
  right: -2px;
  background: red;
  border-radius: 0.7em;
}

.tr-hover-place {
  position: relative;
  min-height: 2.5em; /* button size */
  display: inline;
}
.tr-hover-buttons {
  position: absolute;
  left: 0.35em;
  margin-top: -1rem; /* - 50% button size */
  z-index: 100;
}
tr > td > .tr-hover-place > .tr-hover-buttons {
  display: none;
}
tr:hover > td > .tr-hover-place > .tr-hover-buttons {
  display: flex;
}
.tr-hover-buttons > .ui.button:hover,
.tr-hover-buttons > .ui.button {
  box-shadow: 0 0 3px #555;
  border-color: transparent;
  display: flex;
}
.tr-hover-buttons > .ui.button.follow {
  padding: 0.5rem 1.5rem;
}

.collection-navigation > .response-dimmable {
  min-height: 2rem;
}
.collection-navigation-form.ui.form::after {
  content: '';
  transition: opacity 0s;
  transition-delay: 0.5s;
  opacity: 0;
}
.collection-navigation-form.ui.loading.form::after {
  width: 1.5em;
  height: 1.5em;
  top: auto;
  bottom: -2em;
  z-index: 2000;
  opacity: 1;
}
.collection-navigation-filters {
  margin-bottom: 0.5rem;
}
.collection-navigation-filters:empty {
  display: none;
}
.collection-navigation-buttons-panel .ui.sticky {
  overflow: visible;
}
.collection-navigation-buttons {
  display: flex;
  background: #fff;
  padding-bottom: 0.5rem;
}
.collection-navigation-buttons > .middle-buttons {
  flex: 1;
  text-align: center;
}
.collection-navigation-filters > .array-item-field.form-field {
  padding-top: 0;
  padding-bottom: 0;
}
.collection-navigation-filters .form-field.empty-value .field-input > label {
  opacity: 0.5;
}
.collection-navigation-form + .response-dimmable {
  overflow: auto; /* better look in modal */
}

.collection-navigation-form.ui.loading.form .create.button {
  pointer-events: auto;
  position: relative;
  z-index: 500;
}
.collection-selection-panel:not(:empty) {
  margin-bottom: 0.5rem;
}
.collection-selection-total + .collection-selection-reset {
  margin-left: 1rem;
}
.collection-selection-all > .ui.button,
.collection-selection-reset > .ui.button {
  background: none;
  border: none;
  color: inherit;
}

.paginator > .ui.buttons {
  margin-right: 0;
}
.paginator > .basic.label {
  border: none;
}

.ui.compact.table.data-table > thead > tr > th.resource-actions-column,
.data-table > thead > tr > th.resource-actions-column,
.ui.compact.table td.resource-actions-column,
.resource-actions-column {
  padding: 0;
}

.selection-row {
  width: 1%;
}
td > .select-resource.ui.button {
  width: 100%;
  white-space: nowrap;
}
.select-resource.ui.button > .icon {
  text-decoration: none;
  font-size: 1em;
}
.select-resource.ui.button:not(.selected) > .icon {
  opacity: 0.3;
}
.ui.dimmer .ui.loader.collection-selection-loader::before {
  border-color: rgba(0,0,0,.1); /* not inverted loader */
}
.ui.dimmer .ui.loader.collection-selection-loader::after {
  border-color: #767676 transparent transparent; /* not inverted loader */
}

.ui.horizontal.label.required {
  position: relative;
}
.ui.horizontal.label.required::after, .ui.button.dropdown.required::after {
  content: '*';
  position: absolute;
  right: 3px;
  top: 3px;
}
.ui.horizontal.label.required.empty-value::after, .ui.button.dropdown.required.empty-value::after {
  color: red;
}
.ui.left.labeled.input > .ui.button.dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ui.selection.dropdown.error {
  background: #f0d3d3;
}
.ui.dropdown > a.ui.label {
  text-decoration: none;
}

ul.array-values {
  margin: 0;
  padding-inline-start: 1.5rem;
}

.ui.fluid.input > .DayPickerInput,
.field-input > .DayPickerInput {
  width: 100%;
}
.DayPicker-Day--highlighted, .DayPicker-Day--disabled.DayPicker-Day--highlighted {
  background-color: #64b65b;
  color: white;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + .DayPickerInput > input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + .DayPickerInput > input:not(:focus) {
  border-left-color: transparent;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + .dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + .dropdown:not(.active) {
  border-left-color: transparent;
}
.ui.selection.dropdown .menu {
  max-height: 70vh;
}
.ui.dropdown.to-one-relationship .menu > .header {
  margin: 0;
  text-align: center;
}
.ui.dropdown.to-one-relationship .visible.menu.transition {
  display: flex !important;
  flex-direction: column;
}
.ui.dropdown.to-one-relationship .menu > .header {
  order: 1;
}
.ui.selection.dropdown.to-one-relationship .menu > .message,
.ui.selection.dropdown.to-one-relationship .menu > .item {
  min-height: auto;
}

.ui.form .ui.checkbox {
  vertical-align: text-top;
}
.ui.form .ui.input .ui.checkbox:not(.disabled) {
  display: flex;
  flex: 1 0 auto;
}
.resource-field.editing > .field-input > .checkbox {
  min-height: 2.7rem;
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}
.ui.form .ui.checkbox:not(.disabled) {
  cursor: pointer;
  border: 1px solid rgba(34,36,38,.15);
}
.ui.form .ui.checkbox:not(.disabled) > label {
  left: 0.6em;
  top: 25%;
}

.single-resource .resource-collection-nav {
  width: 100%;
  margin-top: 0.25rem;
}

.resource-field .resource-collection-nav {
  margin: 0;
}
.folder-content .resource-collection-nav .header-buttons3 {
  float: right;
  position: relative;
  right: 2.5rem;
  top: -2.8rem;
  margin-bottom: -2.5rem;
}
.resource-collection-nav .dimmable {
  clear: both;
}
.resource-collection-nav .header-buttons3 .ui.basic.button:hover,
.resource-collection-nav .header-buttons3 .ui.basic.button:focus {
  background: transparent !important;
}
.ui.placeholder.segment.empty.collection {
  margin: 0;
}

.ui.form .ui.error.message, .ui.form .ui.success.message, .ui.form .ui.warning.message,
.ui.form .error.message, .ui.form .success.message, .ui.form .warning.message {
  display: block;
}

.ui.form > .ui.message {
  width: 100%;
}
.ui.input + .ui.error.message {
  margin-top: 2px;
  padding: 0.67857143em 1em;
}
.ui.input + .ui.error.message > .content > p {
  line-height: 1.21428571em;
}
.ui.error.form .ui.error.message > .content > p:first-letter {
  text-transform: uppercase;
}

.ui.error.message .error-field {
  display: block;
  float: left;
  padding-right: 0.3em;
}
.ui.error.message .error-field::after {
  content: ':';
}
.ui.error.message .error-text {
  display: block;
}
.ui.error.message .error-text::first-letter {
  text-transform: uppercase;
}
.ui.error.message .error-field + .error-text::first-letter {
  text-transform: lowercase;
}
.ui.message > .content > ul.list > li.content:only-child {
  margin-left: 0;
}
.ui.message > .content > ul.list > li.content:only-child::before {
  content: '';
}

.to-many-relationship-all {
  width: 100%;
}
.emptiness-label {
  display: inline-block;
}
.nested-resource {
  display: flex;
  align-items: flex-start;
}
.nested-resource-control {
  padding-top: 0.42857143em;
}
.nested-resource-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.ui.button.nested-resource-button {
  min-height: 2.7rem;
}
.nested-resource.reading > .nested-resource-control {
  border-top: 1px solid rgba(34,36,38,0.15);
}
.nested-resource.reading > .nested-resource-control > .ui.button.nested-resource-button {
  padding: 0.3rem;
  min-height: 1.75rem;
  cursor: cell;
}
.ui.button.nested-resource-button {
  font-size: 0.8rem;
}
.nested-resource-template .resource-field > .field-input > label {
  opacity: 0.45;
}
.nested-resource-template .ui.button.nested-resource-button {
  font-size: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.state-event-buttons.ui.buttons {
  display: inline-block;
  vertical-align: top;
  margin: 0 .25rem 0 0;
}
.event-form {
  width: 100%;
}
.next-state-after-event {
  width: 100%;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}
