/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

.login.grid {
  height: 100vh;
  margin: 0;
}

.login.column, .login.modal {
  max-width: 22em;
}

.login.segment {
  border-top: 2px solid #2185d0;
}
