.ui.button.state-field-button {
  display: none;
}
.single-resource .ui.button.state-field-button {
  display: inline-block;
}
.resource-field.reading.required > .field-input > .state-field-button {
  background: inherit;
  box-shadow: none;
}

.state-schema {
  width: 100%;
}

.state-chooser {
  margin-top: 1rem;
}

.state-transition-table td {
  width: 50%;
}
.state-transition-table.ui.table tr.selected td:first-child {
  border-top: 2px solid #9370db;
  border-left: 2px solid #9370db;
  border-top-left-radius: 4px;
}
.state-transition-table.ui.table tr.selected td:last-child {
  border-top: 2px solid #9370db;
  border-right: 2px solid #9370db;
  border-top-right-radius: 4px;
}
.ui.celled.table.state-transition-table tr.state-rules-tr td:first-child,
.state-transition-table tr.state-rules-tr td {
  border-bottom: 2px solid #9370db;
  border-left: 2px solid #9370db;
  border-right: 2px solid #9370db;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.state-transition-table td.selected {
  font-weight: 900;
}
.ui.selectable.table tbody tr.state-rules-tr:hover {
  background: inherit !important;
}
.state-rules-tr .conditions {
  font-style: italic;
  margin-bottom: 0.5rem;
}
.state-rules-description {
  white-space: break-spaces;
  margin: 0;
}

.ui.form .ui.checkbox.show-transition-events {
  border: none;
}
.ui.form .ui.checkbox.show-transition-events > label {
  left: 0;
}

.state-diagram {
  text-align: center;
}
.state-diagram.mermaid {
  color: transparent;
}
.state-diagram.mermaid:not([data-processed="true"]) {
  display: none;
}
.state-diagram [id^="mermaid-"] .edgeLabel {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.state-diagram-code {
  display: none;
}
